@import './Assets/class.scss';
@import './Assets/classDecor.scss';
@import './Assets/classIcon.scss';
@import './Assets/heroes.scss';
@import './Assets/item.scss';
@import './Assets/itemDecor.scss';
@import './Assets/levelDecor.scss';
@import './Assets/lineDecor.scss';
@import './Assets/mask.scss';
@import './Assets/originIcon.scss';
@import './Assets/rune.scss';
@import './Assets/origin.scss';
@import './Assets/runeDecor.scss';
@import './Assets/attackType.scss';
@import './Assets/heroGen.scss';
@import './Assets/originDecor.scss';

.scale-30 {
  --tw-scale-x: 0.3;
  --tw-scale-y: 0.3;
}
.scale-50 {
  --tw-scale-x: 0.5;
  --tw-scale-y: 0.5;
}
.pagination {
  position: absolute;
  bottom: 10px;
  display: flex;
  padding-left: 0;
  list-style: none;
  align-items: center;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.page-link {
  position: relative;
  display: block;
  color: #da3754;
  text-decoration: none;
  background-color: #1a202c;
  border: 1px solid #2d3748;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #da3754;
  background-color: #1a202c;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #da3754;
  background-color: #1a202c;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #da3754;
  border-color: #da3754;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #1a202c;
  border-color: #2d3748;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.heroCardWrapper {
  flex-basis: calc(50% - 25px);
  flex-shrink: 1;
  box-sizing: border-box;
  margin-right: 25px;
}

.matCard-builder {
  border-radius: 20px;
  border: 1px solid #434344;
  padding: 16px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  width: 268px;
  height: 390px;
}

.matCard-mobile {
  border-radius: 20px;
  border: 1px solid #434344;
  padding: 16px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  width: 268px;
  height: 160px;
}

.matCard-small {
  transform: scale(0.3);
}

.matCard-medium {
  transform: scale(0.5);
}

.imgHero-builder {
  position: relative;
  top: -352px;
  left: 0px;
  width: 252px;
  height: 369px;
  z-index: 20;
}

.originBG-builder {
  position: relative;
  top: 0px;
  left: 0;
  width: 256px;
  z-index: 5;
  width: 252px;
}

.classBorder-builder {
  z-index: 0;
  position: relative;
  top: -742px;
  left: -3%;
  width: 267px;
  height: 390px;
}

.cardMask-builder {
  z-index: 50;
  position: relative;
  top: -1112px;
  left: -1px;
  width: 253px;
  height: 369px;
}

.itemHolder-builder {
  position: relative;
  width: 48px;
  height: auto;
  top: -1470px;
  left: 10px;
  z-index: 9999999;
}

.runeHolder-builder {
  position: relative;
  width: 100%;
  height: auto;
  top: -1484px;
  left: 5px;
  z-index: 999999;
}

.starHolder-builder {
  position: relative;
  width: 100%;
  height: auto;
  top: -1500px;
  left: 10px;
  z-index: 9999999999;
}

.grade-holder-builder {
  position: relative;
  width: 100%;
  top: -1490px;
  z-index: 999999;
  font-size: 30px;
  font-weight: bold;
  color: #19B911;
}

.infoHolder-builder {
  position: relative;
  top: -1500px;
  z-index: 999999999;
  line-height: 0px;
}

.pointer-events-none {
  pointer-events: none;
  cursor: none;
}

.itemBorder1 {
  margin-bottom: 4px;
  pointer-events: none;
}
.itemBorder2 {
  margin-bottom: 4px;
  pointer-events: none;
}

.item {
  transform: scale(0.19);
  transform-origin: top left;
  pointer-events: none;
  position: relative;
  left: 3px;
  top: 3px;
}

.rune {
  transform-origin: top left;
  pointer-events: none;
  position: relative;
}

.attackType-builder {
  transform: scale(0.17);
  position: relative;
  top: -42px;
  left: 0px;
}

.attackType-detail {
  transform: scale(0.24);
  position: relative;
  top: -48px;
  left: -40px;
}

.classImg-builder {
  transform: scale(0.38);
  transform-origin: top left;
  pointer-events: none;
  position: relative;
  left: 4px;
  top: 4px;
}

.className {
  position: relative;
  top: -25px;
  left: -10px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  // font-family: 'bernier';
  white-space: nowrap;
}

.heroID {
  position: relative;
  top: -56px;
  left: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  white-space: nowrap;
}

.runeBorder {
  display: inline-block;
}

.rune1 {
  margin-bottom: -3px;
  width: 48px;
  height: 48px;
}

.rune2 {
  margin-bottom: -1px;
  height: 48px;
  width: 48px;
}

.rune3 {
  height: 48px;
  width: 48px;
}

.circle1-builder {
  position: relative;
  left: 146px;
  z-index: 99999999;
  width: 52px;
  height: 52px;
  transform: scale(1.2);
}

.circle3-builder {
  position: relative;
  left: 146px;
  z-index: 99999999;
  width: 52px;
  height: 52px;
  transform: scale(1.1);
}

.circle1-border {
  transform: scale(1.2);
}

.circle3-border {
  transform: scale(1.2);
}

.circle1-image {
  margin: auto;
  transform: scale(0.3);
}

.circle3-image {
  transform: scale(0.3);
  margin: auto;
}

.circle2-builder {
  position: relative;
  top: -1470px;
  z-index: 999999999;
  width: 100%;
  height: 64px;
  left: 5px;
}

.circle2-image {
  transform: scale(0.3);
  margin: auto;
}
.circle2-line {
  position: relative;
  left: 27px;
  top: -103px;
}

.scale-63 {
  --tw-scale-x: .63;
  --tw-scale-y: .63;
  transform: var(--tw-transform);
}

.nftCard-builder {
  width: 240px;
  height: 346px;
  margin-bottom: 40px;
  cursor: pointer;
  max-width: 240px;
  max-height: 346px;
  top: 30px;
  left: 10%;
  display: block;
  position: absolute;
}

.hero-card-bg-builder {
  width: 288px;
  height: 412px;
  cursor: pointer;
  margin-left: -3%;
}

.buttonInfo {
  position: absolute;
  top: -190px;
  left: 70px;
  z-index: 0;
  width: 100px;
}

.my-swal {
  z-index: 99999999999999999999;
}

.heroInfo {
  position: relative;
  top: -1114px;
  width: 50%;
  z-index: 99999999999;
  left: 71px;
}

.badge {
  position: relative;
  left: 12px;
  top: -10px;
  text-align: center;
  transform: scale(1);
}

.badge-text {
  // font-family: 'bernier';
  position: relative;
  top: 11px;
  color: black;
  font-style: normal;
  font-size: 15px;
  font-weight: bolder;
}

.heroDescription-builder {
  position: relative;
  top: -35px;
  left: 55px;
  text-align: left;
  width: 76%;
}

.heroDescription .heroName {
  // font-family: 'bernier';
  font-size: 22px;
  display: flex;
  height: 9px;
}

.heroDescription .shortDesc {
  max-width: 170px;
  max-height: 45px;
  font-size: 10px;
  top: -3px;
  position: relative;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  .nftCard-builder {
    width: 243px;
    height: 150px;
    margin-bottom: 4px;
    cursor: pointer;
    max-width: 243px;
    max-height: 150px;
    display: block;
    position: absolute;
  }
}