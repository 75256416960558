// .mask {
//     background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/mask.png');
//     background-repeat: no-repeat;
//     display: block;
// }
.mask {
    background-image: url('./new-mask.svg');
    background-repeat: no-repeat;
    display: block;
}

.cardElement-Mask {
    width: 253px;
    height: 369px;
    background-position: 0px 155px;
}
