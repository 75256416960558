.item {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/item.png');
    background-repeat: no-repeat;
    display: block;
}

.cardElement-item1 {
    width: 138px;
    height: 138px;
    background-position: -5px -5px;
}

.cardElement-item10 {
    width: 140px;
    height: 139px;
    background-position: -153px -5px;
}

.cardElement-item11 {
    width: 143px;
    height: 139px;
    background-position: -303px -5px;
}

.cardElement-item12 {
    width: 138px;
    height: 138px;
    background-position: -456px -5px;
}

.cardElement-item13 {
    width: 138px;
    height: 138px;
    background-position: -604px -5px;
}

.cardElement-item14 {
    width: 138px;
    height: 138px;
    background-position: -752px -5px;
}

.cardElement-item15 {
    width: 138px;
    height: 138px;
    background-position: -5px -153px;
}

.cardElement-item16 {
    width: 138px;
    height: 138px;
    background-position: -456px -153px;
}

.cardElement-item17 {
    width: 138px;
    height: 138px;
    background-position: -604px -153px;
}

.cardElement-item18 {
    width: 138px;
    height: 138px;
    background-position: -752px -153px;
}

.cardElement-item19 {
    width: 138px;
    height: 140px;
    background-position: -5px -301px;
}

.cardElement-item2 {
    width: 138px;
    height: 138px;
    background-position: -153px -301px;
}

.cardElement-item20 {
    width: 138px;
    height: 138px;
    background-position: -301px -301px;
}

.cardElement-item21 {
    width: 138px;
    height: 138px;
    background-position: -449px -301px;
}

.cardElement-item22 {
    width: 138px;
    height: 138px;
    background-position: -597px -301px;
}

.cardElement-item23 {
    width: 138px;
    height: 138px;
    background-position: -745px -301px;
}

.cardElement-item24 {
    width: 138px;
    height: 138px;
    background-position: -153px -449px;
}

.cardElement-item25 {
    width: 138px;
    height: 138px;
    background-position: -301px -449px;
}

.cardElement-item26 {
    width: 138px;
    height: 138px;
    background-position: -449px -449px;
}

.cardElement-item27 {
    width: 138px;
    height: 138px;
    background-position: -597px -449px;
}

.cardElement-item28 {
    width: 138px;
    height: 138px;
    background-position: -745px -449px;
}

.cardElement-item29 {
    width: 138px;
    height: 138px;
    background-position: -5px -597px;
}

.cardElement-item3 {
    width: 138px;
    height: 138px;
    background-position: -153px -597px;
}

.cardElement-item30 {
    width: 138px;
    height: 138px;
    background-position: -301px -597px;
}

.cardElement-item31 {
    width: 138px;
    height: 138px;
    background-position: -449px -597px;
}

.cardElement-item32 {
    width: 138px;
    height: 138px;
    background-position: -597px -597px;
}

.cardElement-item33 {
    width: 138px;
    height: 138px;
    background-position: -745px -597px;
}

.cardElement-item34 {
    width: 138px;
    height: 138px;
    background-position: -5px -745px;
}

.cardElement-item35 {
    width: 138px;
    height: 138px;
    background-position: -153px -745px;
}

.cardElement-item36 {
    width: 138px;
    height: 138px;
    background-position: -301px -745px;
}

.cardElement-item37 {
    width: 138px;
    height: 138px;
    background-position: -449px -745px;
}

.cardElement-item38 {
    width: 138px;
    height: 138px;
    background-position: -597px -745px;
}

.cardElement-item39 {
    width: 138px;
    height: 138px;
    background-position: -745px -745px;
}

.cardElement-item4 {
    width: 138px;
    height: 138px;
    background-position: -900px -5px;
}

.cardElement-item40 {
    width: 138px;
    height: 138px;
    background-position: -900px -153px;
}

.cardElement-item41 {
    width: 138px;
    height: 138px;
    background-position: -893px -301px;
}

.cardElement-item42 {
    width: 138px;
    height: 138px;
    background-position: -893px -449px;
}

.cardElement-item43 {
    width: 138px;
    height: 138px;
    background-position: -893px -597px;
}

.cardElement-item44 {
    width: 138px;
    height: 138px;
    background-position: -893px -745px;
}

.cardElement-item5 {
    width: 138px;
    height: 138px;
    background-position: -5px -893px;
}

.cardElement-item6 {
    width: 138px;
    height: 138px;
    background-position: -153px -893px;
}

.cardElement-item7 {
    width: 138px;
    height: 138px;
    background-position: -301px -893px;
}

.cardElement-item8 {
    width: 138px;
    height: 138px;
    background-position: -449px -893px;
}

.cardElement-item9 {
    width: 138px;
    height: 138px;
    background-position: -597px -893px;
}
