.class {
    // background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/class.png');
    // background-image: url('./Card-BG.svg');
    background-repeat: no-repeat;
    display: block;
    border-radius: 15px;
}
.cardElement-Class01 {
    width: 256px;
    height: 373px;
    background: #EBEBFF;
}

.cardElement-Class07 {
    width: 256px;
    height: 373px;
    background: #2CC7FF;
}

.cardElement-Class04 {
    width: 256px;
    height: 373px;
    background: #00D709;
}

.cardElement-Class10 {
    width: 256px;
    height: 373px;
    background: #CE6823;
}

.cardElement-Class05 {
    width: 256px;
    height: 373px;
    background: #FD2802;
}

.cardElement-Class02 {
    width: 256px;
    height: 373px;
    background: #E46D82;
}

.cardElement-Class06 {
    width: 256px;
    height: 373px;
    background: #A11411;
}

.cardElement-Class09 {
    width: 256px;
    height: 373px;
    background: #FFB3F0;
}

.cardElement-Class03 {
    width: 256px;
    height: 373px;
    background: #FFDF00;
}

.cardElement-Class08 {
    width: 256px;
    height: 373px;
    background: #D52EF4;
}
