.classDecor {
    background-image: url(classDecor.png);
    background-repeat: no-repeat;
    display: block;
}

.classDecor-class_decor0 {
    width: 30px;
    height: 31px;
    background-position: -5px -5px;
}

.classDecor-class_decor1 {
    width: 31px;
    height: 31px;
    background-position: -45px -5px;
}

.classDecor-class_decor10 {
    width: 31px;
    height: 31px;
    background-position: -86px -5px;
}

.classDecor-class_decor2 {
    width: 31px;
    height: 31px;
    background-position: -5px -46px;
}

.classDecor-class_decor3 {
    width: 31px;
    height: 31px;
    background-position: -46px -46px;
}

.classDecor-class_decor4 {
    width: 31px;
    height: 31px;
    background-position: -87px -46px;
}

.classDecor-class_decor5 {
    width: 31px;
    height: 31px;
    background-position: -5px -87px;
}

.classDecor-class_decor6 {
    width: 31px;
    height: 31px;
    background-position: -46px -87px;
}

.classDecor-class_decor7 {
    width: 31px;
    height: 31px;
    background-position: -87px -87px;
}

.classDecor-class_decor8 {
    width: 31px;
    height: 31px;
    background-position: -127px -5px;
}

.classDecor-class_decor9 {
    width: 31px;
    height: 31px;
    background-position: -128px -46px;
}
