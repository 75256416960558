.heroes {
  background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/heroes3.png');
  background-repeat: no-repeat;
  display: block;
}
.heroes-1 {
    width: 252px;
    height: 368px;
    background-position: -5px -5px;
}

.heroes-10 {
    width: 252px;
    height: 368px;
    background-position: -267px -5px;
}

.heroes-11 {
    width: 252px;
    height: 368px;
    background-position: -529px -5px;
}

.heroes-12 {
    width: 252px;
    height: 368px;
    background-position: -791px -5px;
}

.heroes-13 {
    width: 252px;
    height: 368px;
    background-position: -1053px -5px;
}

.heroes-14 {
    width: 252px;
    height: 368px;
    background-position: -1315px -5px;
}

.heroes-15 {
    width: 254px;
    height: 370px;
    background-position: -1577px -5px;
}

.heroes-16 {
    width: 252px;
    height: 368px;
    background-position: -5px -385px;
}

.heroes-17 {
    width: 252px;
    height: 368px;
    background-position: -267px -385px;
}

.heroes-18 {
    width: 252px;
    height: 368px;
    background-position: -529px -385px;
}

.heroes-19 {
    width: 252px;
    height: 368px;
    background-position: -791px -385px;
}

.heroes-2 {
    width: 252px;
    height: 368px;
    background-position: -1053px -385px;
}

.heroes-20 {
    width: 252px;
    height: 368px;
    background-position: -1315px -385px;
}

.heroes-21 {
    width: 252px;
    height: 368px;
    background-position: -1577px -385px;
}

.heroes-22 {
    width: 252px;
    height: 368px;
    background-position: -5px -763px;
}

.heroes-23_ {
    width: 252px;
    height: 368px;
    background-position: -267px -763px;
}

.heroes-24 {
    width: 252px;
    height: 368px;
    background-position: -529px -763px;
}

.heroes-25 {
    width: 252px;
    height: 368px;
    background-position: -791px -763px;
}

.heroes-26 {
    width: 252px;
    height: 368px;
    background-position: -1053px -763px;
}

.heroes-27 {
    width: 252px;
    height: 368px;
    background-position: -1315px -763px;
}

.heroes-28 {
    width: 252px;
    height: 368px;
    background-position: -1577px -763px;
}

.heroes-29 {
    width: 252px;
    height: 368px;
    background-position: -5px -1141px;
}

.heroes-3 {
    width: 252px;
    height: 368px;
    background-position: -267px -1141px;
}

.heroes-30 {
    width: 252px;
    height: 368px;
    background-position: -529px -1141px;
}

.heroes-31 {
    width: 252px;
    height: 368px;
    background-position: -791px -1141px;
}

.heroes-32 {
    width: 252px;
    height: 368px;
    background-position: -1053px -1141px;
}

.heroes-33 {
    width: 252px;
    height: 368px;
    background-position: -1315px -1141px;
}

.heroes-34 {
    width: 252px;
    height: 368px;
    background-position: -1577px -1141px;
}

.heroes-35 {
    width: 252px;
    height: 368px;
    background-position: -1841px -5px;
}

.heroes-4 {
    width: 252px;
    height: 368px;
    background-position: -1841px -383px;
}

.heroes-5 {
    width: 252px;
    height: 368px;
    background-position: -1839px -761px;
}

.heroes-6 {
    width: 252px;
    height: 368px;
    background-position: -1839px -1139px;
}

.heroes-7 {
    width: 254px;
    height: 370px;
    background-position: -1839px -1517px;
}

.heroes-8 {
    width: 252px;
    height: 368px;
    background-position: -2103px -5px;
}

.heroes-9 {
    width: 252px;
    height: 368px;
    background-position: -2103px -383px;
}
