.originIcon {
    // background-image: url(originIcon.png);
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/originIcon.png');
    background-repeat: no-repeat;
    display: block;
}

.cardElement-Origin_Icon_1 {
    width: 31px;
    height: 31px;
    background-position: -5px -5px;
}

.cardElement-Origin_Icon_10 {
    width: 31px;
    height: 31px;
    background-position: -46px -5px;
}

.cardElement-Origin_Icon_2 {
    width: 32px;
    height: 31px;
    background-position: -87px -5px;
}

.cardElement-Origin_Icon_3 {
    width: 31px;
    height: 31px;
    background-position: -5px -46px;
}

.cardElement-Origin_Icon_4 {
    width: 32px;
    height: 31px;
    background-position: -46px -46px;
}

.cardElement-Origin_Icon_5 {
    width: 31px;
    height: 31px;
    background-position: -88px -46px;
}

.cardElement-Origin_Icon_6 {
    width: 32px;
    height: 31px;
    background-position: -5px -87px;
}

.cardElement-Origin_Icon_7 {
    width: 32px;
    height: 31px;
    background-position: -47px -87px;
}

.cardElement-Origin_Icon_8 {
    width: 31px;
    height: 31px;
    background-position: -89px -87px;
}

.cardElement-Origin_Icon_9 {
    width: 32px;
    height: 31px;
    background-position: -129px -5px;
}
