.classDecor {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/classDecor.png');
    background-repeat: no-repeat;
    display: block;
}

.cardElement-class_decor1 {
    width: 31px;
    height: 31px;
    background-position: -5px -5px;
}

.cardElement-class_decor2 {
    width: 31px;
    height: 31px;
    background-position: -46px -5px;
}

.cardElement-class_decor3 {
    width: 31px;
    height: 31px;
    background-position: -87px -5px;
}

.cardElement-class_decor4 {
    width: 31px;
    height: 31px;
    background-position: -5px -46px;
}

.cardElement-class_decor5 {
    width: 31px;
    height: 31px;
    background-position: -46px -46px;
}

.cardElement-class_decor6 {
    width: 31px;
    height: 31px;
    background-position: -87px -46px;
}

.cardElement-class_decor7 {
    width: 31px;
    height: 31px;
    background-position: -5px -87px;
}

.cardElement-class_decor8 {
    width: 31px;
    height: 31px;
    background-position: -46px -87px;
}

.cardElement-class_decor9 {
    width: 31px;
    height: 31px;
    background-position: -87px -87px;
}

.cardElement-class_decor10 {
    width: 31px;
    height: 31px;
    background-position: -128px -5px;
}
