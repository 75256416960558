.rune {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/rune.png');
    background-repeat: no-repeat;
    display: block;
}

.cardElement-rune1 {
    width: 30px;
    height: 31px;
    background-position: -5px -5px;
}

.cardElement-rune10 {
    width: 31px;
    height: 31px;
    background-position: -45px -5px;
}

.cardElement-rune11 {
    width: 31px;
    height: 31px;
    background-position: -86px -5px;
}

.cardElement-rune12 {
    width: 31px;
    height: 31px;
    background-position: -127px -5px;
}

.cardElement-rune13 {
    width: 29px;
    height: 31px;
    background-position: -5px -46px;
}

.cardElement-rune14 {
    width: 32px;
    height: 31px;
    background-position: -44px -46px;
}

.cardElement-rune15 {
    width: 31px;
    height: 31px;
    background-position: -86px -46px;
}

.cardElement-rune16 {
    width: 31px;
    height: 31px;
    background-position: -127px -46px;
}

.cardElement-rune17 {
    width: 30px;
    height: 31px;
    background-position: -5px -87px;
}

.cardElement-rune18 {
    width: 30px;
    height: 31px;
    background-position: -45px -87px;
}

.cardElement-rune19 {
    width: 31px;
    height: 31px;
    background-position: -85px -87px;
}

.cardElement-rune2 {
    width: 31px;
    height: 31px;
    background-position: -126px -87px;
}

.cardElement-rune20 {
    width: 29px;
    height: 31px;
    background-position: -5px -128px;
}

.cardElement-rune3 {
    width: 28px;
    height: 31px;
    background-position: -44px -128px;
}

.cardElement-rune4 {
    width: 31px;
    height: 31px;
    background-position: -82px -128px;
}

.cardElement-rune5 {
    width: 31px;
    height: 31px;
    background-position: -123px -128px;
}

.cardElement-rune6 {
    width: 30px;
    height: 31px;
    background-position: -168px -5px;
}

.cardElement-rune7 {
    width: 29px;
    height: 31px;
    background-position: -168px -46px;
}

.cardElement-rune8 {
    width: 30px;
    height: 31px;
    background-position: -167px -87px;
}

.cardElement-rune9 {
    width: 31px;
    height: 31px;
    background-position: -164px -128px;
}
