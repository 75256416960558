.itemDecor {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/itemDecor.png');
    background-repeat: no-repeat;
    display: block;
    transform: scale(1.1);
}

.itemDecor-item_decor1 {
    width: 31px;
    height: 31px;
    background-position: -5px -5px;
}

.itemDecor-item_decor10 {
    width: 31px;
    height: 31px;
    background-position: -46px -5px;
}

.itemDecor-item_decor2 {
    width: 32px;
    height: 31px;
    background-position: -87px -5px;
}

.itemDecor-item_decor3 {
    width: 32px;
    height: 31px;
    background-position: -5px -46px;
}

.itemDecor-item_decor4 {
    width: 32px;
    height: 31px;
    background-position: -47px -46px;
}

.itemDecor-item_decor5 {
    width: 32px;
    height: 31px;
    background-position: -89px -46px;
}

.itemDecor-item_decor6 {
    width: 32px;
    height: 31px;
    background-position: -5px -87px;
}

.itemDecor-item_decor7 {
    width: 32px;
    height: 31px;
    background-position: -47px -87px;
}

.itemDecor-item_decor8 {
    width: 32px;
    height: 31px;
    background-position: -89px -87px;
}

.itemDecor-item_decor9 {
    width: 32px;
    height: 31px;
    background-position: -129px -5px;
}
