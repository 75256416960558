.lineDecor {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/lineDecor.png');
    background-repeat: no-repeat;
    display: block;
    transform: scale(0.9);
}

.lineDecor-line_decor1 {
    width: 193px;
    height: 6px;
    background-position: -5px -5px;
}

.lineDecor-line_decor10 {
    width: 193px;
    height: 6px;
    background-position: -5px -21px;
}

.lineDecor-line_decor2 {
    width: 193px;
    height: 6px;
    background-position: -5px -37px;
}

.lineDecor-line_decor3 {
    width: 193px;
    height: 6px;
    background-position: -5px -53px;
}

.lineDecor-line_decor4 {
    width: 193px;
    height: 6px;
    background-position: -5px -69px;
}

.lineDecor-line_decor5 {
    width: 193px;
    height: 6px;
    background-position: -5px -85px;
}

.lineDecor-line_decor6 {
    width: 193px;
    height: 6px;
    background-position: -5px -101px;
}

.lineDecor-line_decor7 {
    width: 193px;
    height: 6px;
    background-position: -5px -117px;
}

.lineDecor-line_decor8 {
    width: 193px;
    height: 6px;
    background-position: -5px -133px;
}

.lineDecor-line_decor9 {
    width: 193px;
    height: 6px;
    background-position: -5px -149px;
}
