.class-builder{
    // background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/class.png');
    background-image: url('./Card-BG.svg');
    background-repeat: no-repeat;
    display: block;
    border-radius: 15px;
}

.cardElement-Class1 {
    width: 256px;
    height: 373px;
    background: #EBEBFF;
}

.cardElement-Class7 {
    width: 256px;
    height: 373px;
    background: #2CC7FF;
}

.cardElement-Class4 {
    width: 256px;
    height: 373px;
    background: #00D709;
}

.cardElement-Class10 {
    width: 256px;
    height: 373px;
    background: #CE6823;
}

.cardElement-Class5 {
    width: 256px;
    height: 373px;
    background: #FD2802;
}

.cardElement-Class2 {
    width: 256px;
    height: 373px;
    background: #E46D82;
}

.cardElement-Class6 {
    width: 256px;
    height: 373px;
    background: #A11411;
}

.cardElement-Class9 {
    width: 256px;
    height: 373px;
    background: #FFB3F0;
}

.cardElement-Class3 {
    width: 256px;
    height: 373px;
    background: #FFDF00;
}

.cardElement-Class8 {
    width: 256px;
    height: 373px;
    background: #D52EF4;
}
