.attackType {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/attackType.png');
    background-repeat: no-repeat;
    display: block;
}

.attackType-detail {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/attackType.png');
    background-repeat: no-repeat;
    display: block;
}

.attackType-1 { //F
    width: 120px;
    height: 120px;
    background-position: -5px -5px;
}

.attackType-4 { //R
    width: 120px;
    height: 120px;
    background-position: -135px -5px;
}

.attackType-2 { //S
    width: 120px;
    height: 120px;
    background-position: -5px -135px;
}

.attackType-3 { //W
    width: 120px;
    height: 120px;
    background-position: -135px -135px;
}
