.cardElement {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/origin.png');
    background-repeat: no-repeat;
    display: block;
}

.cardElement-Origin01 {
    width: 252px;
    height: 369px;
    background-position: -5px -5px;
}

.cardElement-Origin02 {
    width: 252px;
    height: 368px;
    background-position: -267px -5px;
}

.cardElement-Origin03 {
    width: 252px;
    height: 369px;
    background-position: -529px -5px;
}

.cardElement-Origin04 {
    width: 252px;
    height: 369px;
    background-position: -5px -384px;
}

.cardElement-Origin05 {
    width: 252px;
    height: 369px;
    background-position: -267px -384px;
}

.cardElement-Origin06 {
    width: 252px;
    height: 368px;
    background-position: -529px -384px;
}

.cardElement-Origin07 {
    width: 252px;
    height: 369px;
    background-position: -791px -5px;
}

.cardElement-Origin08 {
    width: 252px;
    height: 369px;
    background-position: -791px -384px;
}

.cardElement-Origin09 {
    width: 252px;
    height: 369px;
    background-position: -5px -763px;
}

.cardElement-Origin10 {
    width: 252px;
    height: 369px;
    background-position: -267px -763px;
}
