.heroGenC {
  background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/C.svg');
  background-repeat: no-repeat;
  display: block;
  background-size: 33px 43px;
}
.heroGenR {
  background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/R.svg');
  background-repeat: no-repeat;
  display: block;
}
.heroGenSR {
  background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/SR.svg');
  background-repeat: no-repeat;
  display: block;
}
.heroGenSSR {
  background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/SSR.svg');
  background-repeat: no-repeat;
  display: block;
}
