.classIcon {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/classIcon.png');
    // background-image: url(classIcon.png);
    background-repeat: no-repeat;
    display: block;
}

.cardElement-Class_Icon1 {
    width: 31px;
    height: 31px;
    background-position: -5px -5px;
}

.cardElement-Class_Icon10 {
    width: 31px;
    height: 31px;
    background-position: -46px -5px;
}

.cardElement-Class_Icon2 {
    width: 32px;
    height: 31px;
    background-position: -87px -5px;
}

.cardElement-Class_Icon3 {
    width: 31px;
    height: 31px;
    background-position: -5px -46px;
}

.cardElement-Class_Icon4 {
    width: 32px;
    height: 31px;
    background-position: -46px -46px;
}

.cardElement-Class_Icon5 {
    width: 31px;
    height: 31px;
    background-position: -88px -46px;
}

.cardElement-Class_Icon6 {
    width: 32px;
    height: 31px;
    background-position: -5px -87px;
}

.cardElement-Class_Icon7 {
    width: 32px;
    height: 31px;
    background-position: -47px -87px;
}

.cardElement-Class_Icon8 {
    width: 31px;
    height: 31px;
    background-position: -89px -87px;
}

.cardElement-Class_Icon9 {
    width: 32px;
    height: 31px;
    background-position: -129px -5px;
}