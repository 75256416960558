.levelDecor {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/levelDecor.png');
    background-repeat: no-repeat;
    display: block;
}

.cardElement-level_decor1 {
    width: 33px;
    height: 43px;
    background-position: -5px -5px;
}

.cardElement-level_decor2 {
    width: 33px;
    height: 43px;
    background-position: -48px -5px;
}

.cardElement-level_decor3 {
    width: 33px;
    height: 43px;
    background-position: -91px -5px;
}

.cardElement-level_decor4 {
    width: 33px;
    height: 43px;
    background-position: -5px -58px;
}

.cardElement-level_decor5 {
    width: 33px;
    height: 43px;
    background-position: -48px -58px;
}

.cardElement-level_decor6 {
    width: 33px;
    height: 43px;
    background-position: -91px -58px;
}

.cardElement-level_decor7 {
    width: 33px;
    height: 43px;
    background-position: -134px -5px;
}

.cardElement-level_decor8 {
    width: 33px;
    height: 43px;
    background-position: -134px -58px;
}

.cardElement-level_decor9 {
    width: 33px;
    height: 43px;
    background-position: -5px -111px;
}

.cardElement-level_decor10 {
    width: 33px;
    height: 43px;
    background-position: -48px -111px;
}
