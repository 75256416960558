.originDecor {
    background-image: url(originDecor.png);
    background-repeat: no-repeat;
    display: block;
}

.originDecor-Origin_decor1 {
    width: 31px;
    height: 33px;
    background-position: -5px -5px;
}

.originDecor-Origin_decor10 {
    width: 31px;
    height: 33px;
    background-position: -46px -5px;
}

.originDecor-Origin_decor2 {
    width: 31px;
    height: 33px;
    background-position: -87px -5px;
}

.originDecor-Origin_decor3 {
    width: 32px;
    height: 33px;
    background-position: -5px -48px;
}

.originDecor-Origin_decor4 {
    width: 31px;
    height: 33px;
    background-position: -47px -48px;
}

.originDecor-Origin_decor5 {
    width: 31px;
    height: 33px;
    background-position: -88px -48px;
}

.originDecor-Origin_decor6 {
    width: 32px;
    height: 33px;
    background-position: -5px -91px;
}

.originDecor-Origin_decor7 {
    width: 31px;
    height: 33px;
    background-position: -47px -91px;
}

.originDecor-Origin_decor8 {
    width: 31px;
    height: 33px;
    background-position: -88px -91px;
}

.originDecor-Origin_decor9 {
    width: 32px;
    height: 33px;
    background-position: -128px -5px;
}
