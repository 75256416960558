.runeDecor {
    background-image: url('https://storage.googleapis.com/cdn.heroestd.io/DappUI/runeDecor.png');
    background-repeat: no-repeat;
    display: block;
}

.runeDecor-rune_decor1 {
    width: 31px;
    height: 31px;
    background-position: -5px -5px;
}

.runeDecor-rune_decor2 {
    width: 31px;
    height: 31px;
    background-position: -46px -5px;
}

.runeDecor-rune_decor3 {
    width: 31px;
    height: 31px;
    background-position: -87px -5px;
}

.runeDecor-rune_decor4 {
    width: 31px;
    height: 31px;
    background-position: -5px -46px;
}

.runeDecor-rune_decor5 {
    width: 31px;
    height: 31px;
    background-position: -46px -46px;
}

.runeDecor-rune_decor6 {
    width: 31px;
    height: 31px;
    background-position: -87px -46px;
}

.runeDecor-rune_decor7 {
    width: 31px;
    height: 31px;
    background-position: -5px -87px;
}

.runeDecor-rune_decor8 {
    width: 31px;
    height: 31px;
    background-position: -46px -87px;
}

.runeDecor-rune_decor9 {
    width: 31px;
    height: 31px;
    background-position: -87px -87px;
}

.runeDecor-rune_decor10 {
    width: 31px;
    height: 31px;
    background-position: -128px -5px;
}
